import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Newsletter from "../components/section-newsletter"
import BackgroundInterior from "../components/background/interior"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO
      title="Privacy Policy"
      description={`
        At microincome.com (“us”, “we”, or “our”) we take our ethical
        responsibilities and your privacy seriously by recognizing that your
        privacy is important.
      `}
    />
    <BackgroundInterior>
      <h1 className="text-center">Privacy Policy</h1>
      <Container theme="light" rounded>
        <p>
          At microincome.com (“us”, “we”, or “our”) we take our ethical
          responsibilities and your privacy seriously by recognizing that your
          privacy is important. We have a strong commitment to providing
          excellent service to all our customers, visitors to this Website, and
          other users of our products and services, including respecting your
          concerns about privacy. This Privacy Policy discloses how we collect
          personal information, the types of personal information we receive and
          collect, our use and disclosure of current and former consumer
          information, as well as the steps that we take to safeguard your
          information. If you use this site, you understand the microincome.com
          Privacy Policy in effect at the time of your use. We hope that this
          disclosure will help increase your confidence in our products and
          services, and enhance your experience. Therefore, in compliance with
          industry standards and regulations enforced typically by federal and
          state governments, we abide by the following Privacy Policy.
        </p>
        <h2>Information Collection and Use</h2>
        <p>
          This Privacy Policy applies to all users of our products and services
          (“Consumers”), including those who have used our website (“Web Site”).
          Please note that this policy applies only to this Web Site, and
          services wherein microincome.com determines the purposes or means of
          processing your personal information, and not to web sites maintained
          by other companies or organizations to which we link, or business
          partners to whom we provide your information, based on your requests
          as relating to the product or service in which you are interest, and
          for whom we serve as service providers. Alternatively, the information
          microincome.com collects on behalf of a third party, to whom we
          provide marketing services, may be subject to both this Privacy
          Policy, and the third party’s privacy policy. For more information on
          those companies or organizations’ personal information practices, and
          your rights, please visit their respective privacy policies.
        </p>
        <p>
          Personal information is information that identifies, relates to,
          describes, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a natural person or
          household, such as a real name, postal address, e-mail address,
          telephone number, payment information, and account ID (“Consumer PII,”
          “personal information,” or “PII”). When other information is directly
          associated with personal information, that information may also be
          considered personal information. Information that is aggregated,
          de-identified, or anonymized is not considered personal information.
          Publicly available information that is made available from federal,
          state, or local government records, also is not personal information.
        </p>
        <p>
          We may collect, use, share, and/or store the following types of
          personal information, either online or on the phone, and either
          automatically, from you directly, or from third parties, for the
          business purposes as outlined in this Privacy Policy:
        </p>
        <ol>
          <li>full name,</li>
          <li>postal address,</li>
          <li>shipping address,</li>
          <li>internet protocol (IP) address,</li>
          <li>primary and secondary phone numbers,</li>
          <li>e-mail address,</li>
          <li>
            customer record information, including caller ANI, account number,
            and service address,
          </li>
          <li>
            information pertaining to internet or network activity such as a
            consumer’s interaction with an advertisement, and other information
            obtained from cookies and clear gifs, as described further below.
          </li>
        </ol>
        <p>We may collect personal information at the following times:</p>
        <ol>
          <li>
            when you register to receive communications from this website or any
            website owned by the owner of microincome.com,
          </li>
          <li>
            when you seek information or apply for products and services offered
            through this website or any website owned by the owner of
            microincome.com,
          </li>
          <li>
            when you provide personal information to us through feedback,
            survey, or other forms hosted on this site or any website owned by
            the owner of microincome.com,
          </li>
          <li>
            when you visit our Web Site, including through cookies and clear
            gifs, as described further below, and
          </li>
          <li>when you contact us.</li>
        </ol>
        <p>We collect your personal information in order to:</p>
        <ol>
          <li>
            process and fulfill your requests for information, products, and
            services,
          </li>
          <li>solicit your opinions and other feedback,</li>
          <li>conduct research,</li>
          <li>customize the advertising and content you see and receive,</li>
          <li>improve our products and services, and</li>
          <li>
            provide anonymous reporting for internal and external clients.
          </li>
        </ol>
        <p>
          In addition, we may receive information about you from other sources,
          such as service providers and unaffiliated third party marketing
          companies, for tracking purposes. We may add the information we
          receive about you through those means to your existing account. Like
          other web sites, we also automatically receive and record certain
          types of information every time you visit us. We use “cookies” and the
          other information to enhance your experience and present you with
          personalized offers. Likewise, we use third-party advertising
          companies to distribute and track advertisements appearing on our web
          site. These companies may employ cookies and action tags to measure
          advertising effectiveness. More information about cookies can be found
          in our Cookies and IP Addresses section below.
        </p>
        <p>
          At any point, microincome.com reserves the right to collect additional
          consumer information. This Privacy Policy will be updated accordingly
          to reflect those changes.
        </p>
        <h2>Cookies and IP Addresses</h2>
        <p>
          To serve you more efficiently and effectively, we use “cookies”
          technology. A cookie is a piece of data stored on the user’s hard
          drive that contains information about the user. Cookies are not linked
          to Consumer PII. Once the user closes their browser, a cookie
          typically terminates. Cookies enable a web site to “personalize”
          itself for each user and reduce the time it takes for pages to load on
          your computer. In order to optimize your experience, cookies also help
          with the collection of customer tracking data. We may also capture
          customer tracking data on some of our partner’s web sites, related to
          activity involved with the completion of offers listed on our web
          site. This information is used to monitor the performance of our
          partners. If you reject cookies, you may still use our site, but your
          ability to use some areas of our site will be limited. We also keep
          track of Internet Protocol (IP) addresses and may disclose your IP
          address during the course and scope of our business. An IP Address is
          a number that is used by computers on the network to identify your
          computer every time you log on to the Internet.
        </p>
        <p>
          Your browser or device may offer settings that allow you to choose
          whether browser cookies are set and to delete them. For more
          information about these controls and to exercise your cookie
          preferences, visit your browser or device’s help material. If you
          choose to reject cookies, as noted above, you may not be able to use
          certain features of our websites and services. To learn more about
          Cookies, visit http://www.allaboutcookies.org.
        </p>
        <p>
          Some of our non-affiliated business partners (e.g., advertisers) may
          use cookies on our site. We have no access to or control over such
          cookies. This Privacy Policy covers the use of our cookies only and
          does not cover the use of cookies by any advertisers.
        </p>
        <h2>Sharing of Your Personal Information</h2>
        <p>
          Except as expressly set forth herein, (i) we do not sell your personal
          information, and (ii) microincome.com also does not share your
          personal information with partners or providers associated with other
          domains but may share with other domains owned by the owner of
          microincome.com.
        </p>
        <p>
          We may share your personal information to the following categories of
          third parties, and/or under the following circumstances:
        </p>
        <p>
          With other persons, if we believe it is necessary to 1) comply with
          applicable laws; (2) respond to governmental inquiries; (3) comply
          with valid legal process; or (4) protect the rights or property of
          microincome.com, microincome.com's web sites or other Consumers.
        </p>
        <p>
          In connection with, or during the negotiation of, any merger, sale of
          company stock or assets, financing, acquisition, divestiture, or
          dissolution of all or a portion of the business. Any successor company
          will continue to be bound by this Privacy Policy, unless it is
          modified as described by this policy.
        </p>
        <p>
          When you request to be contacted through microincome.com or our other
          sites that we operate, you are authorizing us to contact you through
          text messaging, email, or letter mail. If you do not want further
          communication from them or us, please notify the person or entity that
          contacts you directly.
        </p>
        <p>
          Additionally, microincome.com may share Consumer PII in aggregate
          (non-personal information), with select companies that wish to reach a
          particular Consumer target audience (e.g., female Consumers between
          the ages of 25-35, who have an average household income of over
          $50,000.00). This information will allow these entities to provide
          relevant offers to specific Consumers.
        </p>
        <p>
          Microincome.com prohibits the sale or transfer of Consumer PII to
          non-affiliated entities for their use without giving you the
          opportunity to opt-out. Although microincome.com advises everyone who
          receives Consumer PII from microincome.com to support this Privacy
          Policy, microincome.com has no control over such party’s information
          handling practices and is not responsible for any failure of such
          party to adhere to this Privacy Policy.
        </p>
        <h2>Service Providers</h2>
        <p>
          In some cases, we may require the use of third party vendors, outside
          companies, to operate portions of the microincome.com web site and/or
          to provide certain services including clerical, information processing
          and shipping services. The particular section of the web site may have
          a similar appearance as the microincome.com site, and information
          submitted through these outside third parties are subject to this
          Privacy Policy unless otherwise stated.
        </p>
        <p>
          When you sign up for our services, we will share only as much
          information as is necessary for the provision of those services. We
          require that all third parties, hired by microincome.com, comply with
          our Privacy Policy in order to keep your information safe and secure.
          These third parties are prohibited from using or sharing your personal
          information for any purpose other than the job they were hired to do.
        </p>
        <h2>Advertisements & Pop Up Windows</h2>
        <p>
          On occasion, you may receive advertisements for microincome.com from
          third parties. These advertisements are served by the third party web
          site that you have visited or directly from this website or any site
          owned by the owner of microincome.com. In addition, such
          advertisements may be placed by third party ad servers or ad networks.
          We may maintain relationships with a number of the third party ad
          networks currently operating. You may find more information at the web
          site of either the individual ad network or the network advertising
          initiative. Your relationships, transactions and any opt-out requests
          with any such third party are governed by the specific policy such
          third party has, and not those of microincome.com.
        </p>
        <h2>Google</h2>
        <p>
          We may use Google's advertising products and technologies on our
          website. Google may be enabled as a third party to run ads and collect
          website performance data. Their policies can be found at
          https://www.google.com/intl/en_us/privacy/partners/.
        </p>
        <p>
          Google, as a third party vendor, uses cookies to serve ads on our
          site. Google's use of the DART cookie enables it to serve ads to our
          users based on their visit to our site and other sites on the
          Internet. Users may opt out of the use of the DART cookie by visiting
          the Google ad and content network privacy policy.
        </p>
        <p>We may implement the following:</p>
        <ul>
          <li>Remarketing with Google AdSense</li>
          <li>Google Display Network Impression Reporting</li>
          <li>Demographics and Interests Reporting</li>
        </ul>
        <p>
          We along with third-party vendors, such as Google use first-party
          cookies (such as the Google Analytics cookies) and third-party cookies
          (such as the DoubleClick cookie) or other third-party identifiers
          together to compile data regarding user interactions with ad
          impressions, and other ad service functions as they relate to our
          website.
        </p>
        <h2>Opting-Out</h2>
        <p>
          We offer you choices when we ask for personal information, whenever
          reasonably possible. We provide you the opportunity to “opt-out” of
          having your personal information used for certain purposes, when we
          ask for this information. You may decline to provide personal
          information to us and/or refuse cookies in your browser, although some
          of our features or services may not function properly as a result.
        </p>
        <p>
          If you no longer wish to receive newsletter and promotional
          communications, you may opt-out of receiving them by following the
          instructions included in each newsletter or communication or by
          emailing or calling us per the information contained on our contact
          page. You may continue to receive email communications from us up to
          ten (10) business days from the time we receive your unsubscribe
          request.
        </p>
        <p>
          Please note that microincome.com can only control its own mailing list
          and policies. Our business partners may maintain their own mailing
          lists, and you may need to contact these parties directly in order to
          stop receiving their communications. Third party advertisers may also
          advertise our services, and you may have provided those third party
          advertisers consent to receive those communications directly. Where
          that is the case, you may need to contact those third parties directly
          in order to stop receiving their communications. Although
          microincome.com only supports permission-based emails, microincome.com
          does not obligate itself to restrict third parties advertising
          microincome.com beyond that which is required by applicable law.
        </p>
        <h2>Third Party Links</h2>
        <p>
          This Privacy Policy applies to all information that microincome.com
          collects. However, when you visit web sites to which microincome.com
          provides a link, keep in mind you will be interacting with a third
          party that operates under its own privacy and security policies, and
          terms of use. If you choose to obtain certain services through a third
          party site, the data collected by the third party is governed by that
          third party’s privacy policy. A third party site will also have its
          own policy regarding the use of cookies and clear gifs. We encourage
          you to review the privacy policies and terms of use of any other
          service provider from whom you request services.
        </p>
        <h2>Minors</h2>
        <p>
          You must be at least 18 years old to use this web site. We do not
          knowingly collect, use, or disclose personal information about
          visitors under 18 years of age. If you are under 18 years of age, you
          may not use the services offered on our web site unless they are used
          with the consent, permission and supervision of your parents or
          guardians.
        </p>
        <h2>Updating Your Information</h2>
        <p>
          If you want to access or update the personal information you submitted
          to microincome.com please e-mail us at support@microincome.com or
          write us at the following address.
        </p>
        <p>
          microincome.com Attn: Customer Support
          <br />
          4600 Campus Drive, Suite 107, Newport Beach, CA 92660
        </p>
        <h2>Questions and Suggestions</h2>
        <p>
          If you have any questions, concerns or suggestions about our Privacy
          Policy, you may contact us:
        </p>
        <ol>
          <li>By sending an email to: support@microincome.com</li>
          <li>
            By sending a letter to: <br />
            microincome.com
            <br />
            4600 Campus Drive, Suite 107
            <br />
            Newport Beach, CA 92660
          </li>
        </ol>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          Microincome.com reserves the right to update or modify this privacy
          statement at any time. The provisions contained in this Privacy Policy
          supersede all previous notices or policies regarding our privacy
          practices. We encourage you to check our site frequently to see the
          current privacy statement and to be informed of how we are committed
          to protecting your information and providing you with improved content
          on our website in order to enhance your online experience. Upon any
          material changes to the policy statement, we will post those changes
          to this privacy statement, the homepage, and/or other places we deem
          appropriate so that you are aware of what information we collect, how
          we use it, and under what circumstances, if any, we disclose it. Our
          Privacy Policy will indicate the date it was last updated. Your
          continued use of our site will signify your acceptance of the changes
          to our Privacy Policy.
        </p>
        <h2>Your California Privacy Rights</h2>
        <p>
          Companies that collect personal information from California residents
          and disclose such information to Third Parties (including affiliated
          entities) for marketing purposes must, in response to a request by a
          consumer, either (1) provide a list detailing the categories of
          information shared and the entities to which such information was
          provided, or (2) provide a mechanism by which a consumer may opt-out
          of having their information shared with Third Parties. We have elected
          the second option and you may request that your personal information
          not be shared with Third Parties by sending your request, including
          your full name, email address and postal address to:
          support@microincome.com, or by mail to California Privacy Information
          c/o microincome.com.
        </p>
        <p>
          In accordance with California Civil Code Sec. 1798.83, California
          resident users are entitled to know that they may file grievances and
          complaints with California Department of Consumer Affairs, 400 R
          Street, Suite 1080, Sacramento, CA 95814; or by phone at 916-445-1254
          or 800-952-5210 or by email to dca@dca.ca.gov.
        </p>
        <p>
          For more information on how your personal information is collected and
          used, please read this Privacy Policy in its entirety.
        </p>
        <h2>Childrens Online Privacy Protection Act Compliance</h2>
        <p>
          We are in compliance with the requirements of COPPA (Childrens Online
          Privacy Protection Act), we do not knowingly collect any information
          from anyone under 13 years of age. Our website, products and services
          are all directed to people who are at least 13 years old or older.
        </p>
        <h2>Personal Information Report Request</h2>
        <p>
          In accordance with the California Consumer Privacy Act (CCPA) and
          other applicable state and federal regulations, you may request a
          report of personal information collected about you by microincome.com
          within the past twelve (12) months, the categories of sources from
          which such personal information was collected, the purpose of such
          collection and the categories of third parties with whom your personal
          information was shared. This complimentary report may be requested
          twice in a twelve-month period and will be provided within forty-five
          (45) calendar days from the request submission date upon verification
          of your identity. A request authentication measure is in place to
          ensure that your personal information is not being distributed to the
          wrong parties. Failure to comply with all necessary steps will result
          in an incomplete request and your consumer information report will not
          be sent. You may opt to receive your report electronically or by
          postal mail. To request your report, email us at
          privacy@microincome.com.
        </p>
        <h2 id="opt-out">Right to Opt-Out</h2>
        <p>
          As a California resident, you have the right to opt-out of the sale of
          your PII. If you would like to opt-out, then please email us at
          privacy@microincome.com.
        </p>
        <h2>Right Not to be Discriminated Against</h2>
        <p>
          Microincome.com shall not deny any good or service to you, charge you
          a different price, provide a different level or quality of any such
          good or service or suggest you will receive a different price due to
          your exercising any of your rights under the CCPA.
        </p>
        <p>
          PLEASE PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS.
        </p>
        <p>LAST UPDATED 2020</p>
        <p>
          Copyright © microincome.com, {new Date().getFullYear()} All Rights
          Reserved.
        </p>
      </Container>
      <Newsletter />
    </BackgroundInterior>
  </Layout>
)

export default PrivacyPolicyPage
